import { toSnakeCase } from '@utils';
import hubspot from '@services/hubspot.service';

if (!window.analytics) {
  window.analytics = {
    page: (name) => {
      console.info('Tracking Page', name); // eslint-disable-line no-console
    },
    identify: (id, obj) => {
      console.info('Tracking User', id, obj); // eslint-disable-line no-console
    },
  };
}

function extractUtmParams(url) {
  const urlObject = new URL(url);
  const utmParams = {};

  urlObject.searchParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmParams[key] = value;
    }
  });

  return utmParams;
}

export default {
  namespaced: true,

  actions: {
    event: (store, { category, event, props }) => {
      const eventName = `${category}_${event}`;
      // catch missing events for the areas where provider views are loaded into
      // the admin UI
      try {
        window.MetaEvents.event(eventName, toSnakeCase(props));
      } catch (error) {
        console.warn(error); // eslint-disable-line no-console
      }
    },

    pageLoad: (store, path) => {
      window.analytics.page(path);
    },

    identifyUser: (store, { id, traits }) => {
      hubspot.identifyUser(traits);

      const utmParams = extractUtmParams(document.URL);

      window.analytics.identify(id, toSnakeCase({ ...traits, ...utmParams }));

      if (process.env.HOST !== 'www.kinside.com') {
        console.info('Identified user', id, { traits, utmParams }); // eslint-disable-line no-console
      }
    },
  },
};
