import { axios, toCamelCase, toSnakeCase } from '@utils';
import moment from 'moment-timezone';

async function fetchParentSlots(timezone = 'America/Los_Angeles') {
  const { data } = await axios.get('/parent_availability_slots.json');
  return toCamelCase(data).map(({
    date, from, to, id,
  }) => ({
    date,
    id,
    time: {
      from: moment(new Date(from).toLocaleString('en-US', { timeZone: timezone })).format('h:mm a'),
      to: moment(new Date(to).toLocaleString('en-US', { timeZone: timezone })).format('h:mm a'),
    },
  }));
}

async function updateParentSlots(slots) {
  await axios.put('/parent_availability_slots.json', {
    slots,
  });
  return fetchParentSlots();
}

let fetchToursPromise;
async function fetchTours(facilityId, onlyUpcoming) {
  if (fetchToursPromise) {
    return fetchToursPromise;
  }

  const promise = axios.get('/tour_reservations.json', {
    params: {
      upcoming: onlyUpcoming,
      facility_id: facilityId,
    },
  })
    .then(({ data }) => {
      fetchToursPromise = null;
      return toCamelCase(data.reservations);
    })
    .catch(() => {
      fetchToursPromise = null;
    });
  fetchToursPromise = promise;

  return promise;
}

async function fetchLatestTour(facilityId) {
  const tours = await fetchTours(facilityId, true);
  if (tours?.length > 0) {
    const maxTourId = Math.max(...(tours.map((t) => t.id)));
    const tour = tours.find((t) => t.id === maxTourId);
    return tour;
  }
  return null;
}

async function reserveTour({
  facilityId, tourSlotId, useParentSlots, comment, dependentIds,
}) {
  const { data } = await axios.post('/tour_reservations.json', {
    facility_id: facilityId,
    tour_slot_id: tourSlotId,
    use_parent_slots: useParentSlots,
    parent_comment: comment,
    dependent_ids: dependentIds,
  });

  const { successMessage, errorMessage, redirectUrl } = toCamelCase(data);

  if (successMessage) {
    const tour = await fetchLatestTour(facilityId);
    return { tour, redirectUrl };
  }
  throw new Error(errorMessage);
}

async function getTourSettings(params) {
  const { data } = await axios.get('/api/v1/legup/tours/setting', toSnakeCase(params));
  return toCamelCase(data);
}

async function updateSettings(params) {
  const { data } = await axios.post('/api/v1/legup/tours/setting', toSnakeCase(params));
  return toCamelCase(data);
}

async function fetchSingleTour(tourId, centerId, userId) {
  try {
    const { data } = await axios.get(`/api/v1/legup/tours/${tourId}?legup_center_id=${centerId}&user_id=${userId}`);
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function bookTour(centerId, parent, child, tourInfo, noFamilyNotes, ageGroupId) {
  try {
    const { data } = await axios.post('/api/v1/legup/tours/schedule', {
      legup_center_id: centerId,
      parent: toSnakeCase(parent),
      child: toSnakeCase(child),
      tour_info: toSnakeCase(tourInfo),
      no_family_notes: noFamilyNotes,
      age_group_id: ageGroupId,
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function rescheduleTour(tour, newDateTime, userId) {
  try {
    const { data } = await axios.post('/api/v1/legup/tours/reschedule', {
      user_id: userId,
      legup_center_id: tour.centerId || tour.center.centerId,
      tour: toSnakeCase(tour),
      new_date_time: toSnakeCase(newDateTime),
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function cancelTour(tour, userId) {
  try {
    const { data } = await axios.post('/api/v1/legup/tours/cancel', {
      legup_center_id: tour.centerId,
      id: tour.tourId,
      user_id: userId,
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function confirmTour(tourId, centerId, userId) {
  try {
    const { data } = await axios.post('/api/v1/legup/tours/confirm', {
      legup_center_id: centerId,
      id: tourId,
      user_id: userId,
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

let fetchUpcomingParentTourForCenterPromise;
async function fetchUpcomingParentTourForCenter(parentId, legupCenterId) {
  if (fetchUpcomingParentTourForCenterPromise) {
    return fetchUpcomingParentTourForCenterPromise;
  }

  const promise = axios.get('/api/v1/legup/tours', {
    params: {
      parent_id: parentId,
      legup_center_id: legupCenterId,
    },
  })
    .then(({ data }) => {
      fetchUpcomingParentTourForCenterPromise = null;
      return toCamelCase(data);
    })
    .catch((error) => {
      fetchUpcomingParentTourForCenterPromise = null;
      throw new Error(error);
    });
  fetchUpcomingParentTourForCenterPromise = promise;

  return promise;
}

let fetchToursForParentPromise;
async function fetchToursForParent(parentId) {
  if (fetchToursForParentPromise) {
    return fetchToursForParentPromise;
  }

  const promise = axios.get('/api/v1/legup/tours', {
    params: {
      parent_id: parentId,
    },
  })
    .then(({ data }) => {
      fetchToursForParentPromise = null;
      return toCamelCase(data);
    })
    .catch((error) => {
      fetchToursForParentPromise = null;
      throw new Error(error);
    });
  fetchToursForParentPromise = promise;

  return promise;
}

async function centerTourPolicy(params) {
  try {
    const { data } = await axios.get(`/api/v1/legup/tours/policy?legup_center_id=${params.centerId}&user_id=${params.userId}`);
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function tourProviderSetting(params) {
  try {
    const { data } = await axios.get('/api/v1/legup/tours/tours_enabled', {
      params: {
        legup_center_id: params.centerId,
        place: params.place,
      },
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function centerTourSlots(params) {
  try {
    const { data } = await axios.get('/api/v1/legup/tours/slots', {
      params: {
        legup_center_id: params.centerId,
        legup_age_group_id: params.ageGroupId,
        year: params.year,
        month: params.month,
        user_id: params.userId,
      },
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

export {
  fetchParentSlots,
  updateParentSlots,
  reserveTour,
  fetchLatestTour,
  fetchTours,
  getTourSettings,
  updateSettings,
  fetchSingleTour,
  bookTour,
  rescheduleTour,
  cancelTour,
  confirmTour,
  fetchUpcomingParentTourForCenter,
  fetchToursForParent,
  centerTourPolicy,
  tourProviderSetting,
  centerTourSlots,
};
