import { accountValidateEmployerCode, accountResendConfirmationEmail } from './account.service';

export default {
  namespaced: true,

  state: {
    success: false,
    tries: null,
    confirmationDisabled: false,
  },
  mutations: {
    updateValidation: (state, validatedStatus) => {
      state.success = validatedStatus.data.success;
      state.tries = validatedStatus.data.tries;
    },
    disableConfirmation: (state) => {
      state.confirmationDisabled = true;
    },
  },

  actions: {
    async validateEmployerCode({ commit, dispatch }, accessCode) {
      try {
        const validatedStatus = await accountValidateEmployerCode({
          access_code: accessCode,
        });
        if (!validatedStatus.data.success && accessCode !== '') {
          const remainingTries = 3 - validatedStatus.data.tries;
          dispatch('notifications/addToastError', { text: `Could not validate the code. ${remainingTries} tries remaining.` }, { root: true });
        } else if (validatedStatus.data.success && accessCode !== '') {
          window.location.reload();
        }
        commit('updateValidation', validatedStatus);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not validate the code', error }, { root: true });
      }
    },

    async resendConfirmationEmail({ commit, dispatch }) {
      try {
        const validatedStatus = await accountResendConfirmationEmail();
        if (!validatedStatus.data.success) {
          dispatch(
            'notifications/addToastError',
            { text: 'You have reached the maximum amount of confirmation retries, please contact our concierge team.' },
            { root: true },
          );
          commit('disableConfirmation');
        } else {
          dispatch(
            'notifications/addToastNotification',
            { text: 'Sucessfully resent confirmation email!' },
            { root: true },
          );
        }
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Something went wrong resending confirmation instructions, please contact our concierge team.', error },
          { root: true },
        );
      }
    },
  },
};
