import { axios, toCamelCase, toSnakeCase } from '@utils';

async function fetchDependents(params) {
  const response = await axios.get('/api/v1/dependents.json', { params: toSnakeCase(params) });
  return toCamelCase(response.data);
}

async function addDependent(dependent) {
  const response = await axios.post('/dependents.json', { dependent: toSnakeCase(dependent) });
  return toCamelCase(response.data);
}

async function updateDependent(dependent) {
  const response = await axios.put(`/dependents/${dependent.id}.json`, toSnakeCase(dependent));
  return toCamelCase(response.data);
}

async function removeDependent(id) {
  return axios.delete(`/dependents/${id}`);
}

export {
  addDependent,
  updateDependent,
  removeDependent,
  fetchDependents,
};
