import {
  axios,
  toSnakeCase,
  toCamelCase,
  clearEmptyValue,
  getBoundsFromFacilities,
} from '@utils';

function searchParamsToFetchParams({
  location, filters: fil, bounds, geoSearchType, sort, searchRadius,
}) {
  const filters = (() => {
    if (fil) {
      const {
        age,
        startDate,
        onlyEmergencyCare,
        ...rest
      } = fil;
      return {
        dependents: age?.dependents,
        age_ranges: age?.range,
        openings: startDate?.value,
        openings_from: startDate?.date,
        emergency_care: onlyEmergencyCare,
        search_radius: searchRadius,
        ...toSnakeCase(rest),
      };
    }
    return undefined;
  })();

  return clearEmptyValue({
    source_address: location?.targetAddress,
    source_latitude: location?.lat,
    source_longitude: location?.lon,
    precise_address_lat: location?.lat,
    precise_address_lon: location?.lon,
    source_city: location?.city,
    source_state: location?.state,
    source_zip: location?.zip,
    is_precise_address: location?.preciseLocation,
    filters,
    bounds_neLat: bounds?.neLat,
    bounds_neLon: bounds?.neLon,
    bounds_swLat: bounds?.swLat,
    bounds_swLon: bounds?.swLon,
    center_lon: location?.lon,
    center_lat: location?.lat,
    geo_search_type: geoSearchType,
    sort,
  });
}

function fetchParamsToSearchParams({
  filters: fetchFilters,
  /* eslint-disable camelcase */
  bounds_neLat,
  bounds_neLon,
  bounds_swLat,
  bounds_swLon,
  ...rest
}) {
  const {
    dependents,
    age_ranges: range,
    openings,
    openings_from,
    emergency_care: onlyEmergencyCare,
    search_radius: searchRadius,
    ...restFilters
  } = fetchFilters;

  const filters = {
    age: { dependents: dependents || [], range: range || [] },
    startDate: { value: openings, date: openings_from },
    onlyEmergencyCare,
    ...toCamelCase(restFilters),
  };

  const location = {
    targetAddress: rest.source_address,
    lat: parseFloat(rest.source_latitude),
    lon: parseFloat(rest.source_longitude),
    city: rest.source_city,
    state: rest.source_state,
    zip: rest.source_zip,
    preciseLocation: rest.precise_address,
  };

  return ({
    bounds: {
      neLat: bounds_neLat,
      neLon: bounds_neLon,
      swLat: bounds_swLat,
      swLon: bounds_swLon,
    },
    location,
    filters: clearEmptyValue(filters),
    searchRadius,
  });
  /* eslint-enable camelcase */
}

async function fetchSearchHistory() {
  const response = await axios.get('/cd_searches.json');
  return toCamelCase(response.data);
}

async function saveSearch(params) {
  const response = await axios.post('/cd_searches.json', searchParamsToFetchParams(params));
  return toCamelCase(response.data);
}

async function fetchSearch(id) {
  const response = await axios.get(`/cd_searches/${id}.json`);
  return fetchParamsToSearchParams(response.data);
}

function deleteSearch(id) {
  return axios.delete(`/cd_searches/${id}.json`);
}

async function fetchPhilosophies() {
  const response = await axios.get('/facility_philosophies.json');
  return response.data;
}

async function fetchScheduleTypes() {
  const response = await axios.get('/api/v1/schedule_types.json');
  return response.data;
}

// age ranges are disabled
// async function fetchAgeRanges() {
//   const response = await axios.get('/age_groups.json');
//   return toCamelCase(response.data);
// }

async function fetchMotivation() {
  const response = await axios.get('/parent_motivations.json');
  return toCamelCase(response.data);
}

function arrayToString(array = []) {
  return array.join(',');
}

function mapParamsToFetchParams({
  filters: fil, bounds, pagination, location, geoSearchType, sort, searchRadius, searchId,
} = {}) {
  const { filters: fetchFilters, ...rest } = searchParamsToFetchParams({
    filters: fil,
    bounds,
    location,
    geoSearchType,
    sort,
    searchRadius,
  });

  const filters = {};
  if (fil) {
    Object.keys(fetchFilters).forEach((filterName) => {
      const value = fetchFilters[filterName];
      if (value === undefined || (Array.isArray(value) && !value.length)) {
        return;
      }
      filters[`filters[${filterName}]`] = Array.isArray(value) ? arrayToString(value) : value;
    });
  }

  return {
    ...filters,
    ...rest,
    per_page: pagination?.perPage,
    page: pagination?.page,
    search_id: searchId,
  };
}

async function findFacilities(restOfParams) {
  const axiosController = new AbortController();

  try {
    const params = mapParamsToFetchParams(restOfParams);

    const response = await axios.get('/childcare/markers.json', {
      params,
      signal: axiosController.signal,
    });

    const { markers, ...restOfResponse } = response.data;
    const facilities = markers.map((m) => ({ ...m.facility, zipBased: m.zip_based }));

    return toCamelCase({
      ...restOfResponse,
      facilities,
      pagination: {
        page: response.data.current_page,
        totalPages: response.data.total_pages,
        totalRecords: response.data.total_results,
      },
      markers: markers
        .map(({ facility, ...rest }) => ({ ...rest, facilityId: facility.id })),
      boundsToFit: getBoundsFromFacilities(markers),
    });
  } catch (error) {
    if (axiosController.abort()) {
      return undefined;
    }
    throw new Error(error);
  }
}

export {
  fetchSearchHistory,
  saveSearch,
  fetchSearch,
  deleteSearch,
  fetchPhilosophies,
  fetchScheduleTypes,
  searchParamsToFetchParams,
  fetchMotivation,
  findFacilities,
};
