class HubSpotService {
  constructor() {
    if (this.instance) {
      return this.instance; // eslint-disable-line no-constructor-return
    }
    this.instance = this;

    window.hsConversationsSettings = {
      inlineEmbedSelector: '#hubspot-chat-widget',
    };

    if (window.env.AUTHORIZED) {
      if (document.readyState === 'complete' || document.readyState === 'interactive') {
        setTimeout(() => this.loadScript(), 1);
      } else {
        document.addEventListener('DOMContentLoaded', () => this.loadScript());
      }
    }
  }

  async loadScript() {
    if (window.location.href.includes('messaging') || window.location.href.includes('admin')) return;

    const id = 'hs-script-loader';
    const scr = document.getElementById(id);
    if (scr) {
      this.loadHSC();
    } else {
      const scriptTag = document.createElement('script');
      scriptTag.src = 'https://js.hs-scripts.com/4769325.js';
      scriptTag.id = id;

      scriptTag.onload = () => this.loadHSC();
      document.body.appendChild(scriptTag);
    }
  }

  async loadHSC() {
    if (this.widget) {
      return;
    }
    if (window.HubSpotConversations) {
      this.hsc = window.HubSpotConversations;
      this.widget = this.hsc.widget;
      this.loaded = false;
    } else {
      window.hsConversationsOnReady = [() => {
        this.loadHSC();
      }];
    }
  }

  async waitForHSC() {
    return new Promise((resolve) => {
      const checkHSC = () => {
        setTimeout(() => {
          if (this.widget) {
            resolve();
          } else {
            checkHSC();
          }
        }, 300);
      };
      if (this.widget) {
        resolve();
      } else {
        checkHSC();
      }
    });
  }

  async waitForLoadedStatus() {
    await this.waitForHSC();
    return new Promise((resolve) => {
      const checkLoadedState = () => {
        setTimeout(() => {
          if (this.widget.status().loaded) {
            this.loaded = true;
            resolve();
          } else {
            checkLoadedState();
          }
        }, 3000);
      };
      if (this.loaded) {
        resolve();
      } else {
        checkLoadedState();
      }
    });
  }

  async load(widgetOpen = true) {
    if (!this.loaded) {
      await this.waitForHSC();
      await this.widget.load({ widgetOpen });
    }
  }

  async refresh(openToNewThread = false) {
    if (this.loaded) {
      await this.widget.refresh({ openToNewThread });
    } else {
      await this.load();
    }
  }

  async open() {
    if (!this.widget) {
      await this.waitForHSC();
    }
    window.HubSpotConversations.widget.open();
    return this.widget.open();
  }

  close() {
    if (this.loaded) {
      this.widget.close();
    }
  }

  isLoaded() {
    return this.loaded;
  }

  trackEvent(eventName, data) {
    /* eslint-disable no-underscore-dangle */
    window._hsq = window._hsq || [];
    this.hsq = window._hsq;
    window._hsq.push([eventName, data]);
    /* eslint-enable no-underscore-dangle */
  }

  identifyUser({
    firstName, lastName, type, phoneNumber, email,
  } = {}) {
    const contactType = (() => {
      switch (type) {
        case 'parent':
          return 'Parent';
        case 'provider':
          return 'Care provider';
        default:
          return null;
      }
    })();

    this.trackEvent('identify', {
      email,
      firstname: firstName,
      lastname: lastName,
      contact_type: contactType,
      mobilephone: phoneNumber,
    });

    this.trackEvent('trackPageView');

    return new Promise((resolve) => {
      setTimeout(
        async () => {
          await this.refresh();
          resolve();
        },
        1000,
      );
    });
  }
}

const hubspot = new HubSpotService();

export default hubspot;
