import vue from 'vue';
import moment from 'moment-timezone';
import { addArrayToDictionary } from '@utils';
import {
  fetchParentSlots, updateParentSlots, reserveTour, fetchTours,
  getTourSettings, updateSettings, fetchSingleTour,
  fetchUpcomingParentTourForCenter,
  fetchToursForParent,
  tourProviderSetting,
  centerTourPolicy,
  centerTourSlots,
  bookTour, cancelTour, rescheduleTour, confirmTour,
} from './tours.service';

export default {
  namespaced: true,
  state: {
    parentSlots: [],
    tours: {},
    toursAsArray: [],
    centerSettings: {},
    tourPolicy: {},
    tourSlots: {},
    tourEnabled: false,
    upcomingTour: {},
    pastTour: {},
    toursForParent: [],
    tourToConfirm: {},
  },
  mutations: {
    setParentSlots: (state, { slots }) => {
      state.parentSlots = [...slots];
    },
    setTours: (state, payload) => {
      state.tours = { ...addArrayToDictionary(payload, state.tours) };
    },
    setToursAsArray: (state, payload) => {
      state.toursAsArray = [...payload];
    },
    // legup
    setCenterSettings: (state, settings) => {
      state.centerSettings = settings;
    },
    setTourPolicy: (state, { tourPolicy }) => {
      state.tourPolicy = tourPolicy;
    },
    setTourSlots: (state, { tourSlots }) => {
      state.tourSlots = tourSlots;
    },
    setTourSetting: (state, { tourEnabled }) => {
      state.tourEnabled = tourEnabled;
    },
    setCenterParentUpcomingTour: (state, { tour }) => {
      state.upcomingTour = tour;
    },
    setCenterParentPastTour: (state, { tour }) => {
      state.pastTour = tour;
    },
    setToursForParent(state, payload) {
      state.toursForParent = payload;
    },
    setSingleTour(state, tour) {
      state.tourToConfirm = tour;
    },
  },
  getters: {
    tourStartTime: (state) => (tourId, format = 'ddd MMM D h:mma') => {
      const tour = state.tours[tourId];
      const startTime = moment.unix(tour.startAt).tz(tour.timezone);
      return startTime.format(format);
    },
    // legup
    tourProviderSetting(state) {
      return state.tourEnabled;
    },
    centerTourPolicy(state) {
      return state.tourPolicy;
    },
    centerTourSlots(state) {
      return state.tourSlots;
    },
    upcomingTour(state) {
      return state.upcomingTour;
    },
    pastTour(state) {
      return state.pastTour;
    },
    tourToConfirm(state) {
      return state.tourToConfirm;
    },
    centerSettings: (state) => state.centerSettings,
  },
  actions: {
    async fetchParentSlots({ commit, dispatch }, { timezone }) {
      try {
        const slots = await fetchParentSlots(timezone);
        commit('setParentSlots', { slots });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load parent slots', error }, { root: true });
      }
    },

    async fetchTours({ commit, rootState }) {
      const tours = await fetchTours(rootState.facilityId);
      commit('setTours', tours);
      commit('setToursAsArray', tours);
    },
    async updateParentSlots({ commit }, { newSlots, timezone }) {
      const slots = newSlots.map((ps) => {
        const date = vue.moment(ps.date).format('YYYY-MM-DD');
        const slotDateFrom = vue.moment
          .tz(`${date} ${ps.time.from}`, 'YYYY-MM-DD h:mm A', timezone)
          .utc();
        const slotDateTo = vue.moment
          .tz(`${date} ${ps.time.to}`, 'YYYY-MM-DD h:mm A', timezone)
          .utc();

        return {
          id: ps.id,
          from: slotDateFrom,
          to: slotDateTo,
          date: ps.date,
        };
      });
      const updatedSlots = await updateParentSlots(slots);
      commit('setParentSlots', { slots: updatedSlots });
    },
    async reserveTour({ commit, dispatch, rootState }, { tourData, facilityId }) {
      try {
        const { tour, redirectUrl } = await reserveTour(
          {
            ...tourData,
            facilityId,
            dependentIds: Object.keys(rootState.dependents.list),
          },
        );
        if (tour) {
          commit('setTours', [tour]);
        }
        return { redirectUrl, tour };
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not reserve the tour', error }, { root: true });
        throw new Error();
      }
    },
    // legup tour
    async centerParentTour({ rootState, dispatch, commit }, { legupCenterId }) {
      try {
        if (rootState.user.current.legupParentId) {
          const response = await fetchUpcomingParentTourForCenter(
            rootState.user.current.legupParentId,
            legupCenterId,
          );
          if (response?.success) {
            const today = new Date();
            const upcomingTours = response.tours.filter((t) => (
              new Date(new Date(t.startTime).toDateString()) >= today
            ));
            upcomingTours.sort((a, b) => a.startTime.localeCompare(b.startTime));
            commit('setCenterParentUpcomingTour', { tour: (upcomingTours.shift() || {}) });

            const pastTours = response.tours.filter((t) => (
              new Date(new Date(t.startTime).toDateString()) <= today
            ));
            pastTours.sort((a, b) => a.startTime.localeCompare(b.startTime));
            commit('setCenterParentPastTour', { tour: (pastTours.shift() || {}) });
          }
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch legup tours data', error }, { root: true });
      }
    },
    async bookTour({ commit, dispatch }, {
      centerId, parent, child, tourInfo, noFamilyNotes, ageGroupId,
    }) {
      try {
        const response = await bookTour(
          centerId,
          parent,
          child,
          tourInfo,
          noFamilyNotes,
          ageGroupId,
        );
        if (response?.success) {
          commit('setCenterParentUpcomingTour', { tour: response.tour });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not book tour', error }, { root: true });
        throw new Error();
      }
    },
    async cancelTour({ commit, dispatch }, { tour, userId }) {
      try {
        const response = await cancelTour(tour, userId);
        if (response?.success) {
          if (userId) {
            commit('setSingleTour', response.tour);
          } else {
            commit('setCenterParentUpcomingTour', { tour: null });
          }
          dispatch('notifications/addToastSuccess', { text: 'Tour is cancelled successfully' }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not cancel tour', error }, { root: true });
        throw new Error();
      }
    },
    async rescheduleTour({ commit, dispatch, state }, { params }) {
      try {
        const { tour, newDateTime, userId } = params;
        const response = await rescheduleTour(tour, newDateTime, userId);
        if (response?.success) {
          if (userId) {
            const { timezone, policy, ageGroupId } = state.tourToConfirm;
            commit('setSingleTour', {
              ...response.tour, timezone, policy, ageGroupId,
            });
          } else {
            commit('setCenterParentUpcomingTour', { tour: response.tour });
          }
          dispatch('notifications/addToastSuccess', { text: 'Tour is rescheduled successfully' }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not reschedule tour', error }, { root: true });
        throw new Error();
      }
    },
    async fetchSingleTour({ commit }, { tourId, centerId, userId }) {
      const response = await fetchSingleTour(tourId, centerId, userId);
      if (response?.success) {
        commit('setSingleTour', response.tour);
      }
    },
    async parentConfirmTour({ commit, dispatch, state }, { tourId, centerId, userId }) {
      try {
        const response = await confirmTour(tourId, centerId, userId);
        if (response?.success) {
          commit('setSingleTour', { ...state.tourToConfirm, confirmed: true });
          dispatch('notifications/addToastSuccess', { text: 'Tour is confirmed' }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not confirm tour', error }, { root: true });
        throw new Error();
      }
    },
    async fetchToursForParent({ rootState, dispatch, commit }) {
      try {
        if (rootState.user.current.legupParentId) {
          const response = await fetchToursForParent(
            rootState.user.current.legupParentId,
          );
          if (response?.success) {
            commit('setToursForParent', response.tours);
          }
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch all tours for parent', error }, { root: true });
      }
    },
    async tourProviderSetting({ commit, dispatch }, { params }) {
      try {
        const response = await tourProviderSetting(params);
        if (response?.success) {
          commit('setTourSetting', { tourEnabled: response.tourEnabled });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve tour setting', error }, { root: true });
      }
    },
    async centerTourPolicy({ commit, dispatch }, { params }) {
      try {
        const response = await centerTourPolicy(params);
        if (response?.success) {
          const { policy, virtualInstructions, virtualLink } = response;
          commit('setTourPolicy', { tourPolicy: { policy, virtualInstructions, virtualLink } });
        }
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Something went wrong fetching facility tour policy', error },
          { root: true },
        );
      }
    },
    async centerTourSlots({ commit, dispatch }, { params }) {
      try {
        const response = await centerTourSlots(params);
        if (response?.success) {
          commit('setTourSlots', { tourSlots: response.times });
        }
      } catch (error) {
        dispatch(
          'notifications/addToastError',
          { text: 'Something went wrong fetching facility available tours slots', error },
          { root: true },
        );
      }
    },
    async fetchSettings({ commit, dispatch }, params) {
      try {
        const response = await getTourSettings({ params });
        commit('setCenterSettings', response.data);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch your tour settings', error }, { root: true });
      }
    },
    async updateSettings({ commit, dispatch }, params) {
      try {
        const response = await updateSettings(params);
        if (response.success) {
          commit('setCenterSettings', response.data);
          dispatch('notifications/addToastNotification', { text: 'Tour setting updated successfully' }, { root: true });
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch your tour settings', error }, { root: true });
      }
    },
  },
};
