import { getEnvVar } from '@utils';

export default {
  namespaced: true,
  state: {
    toastNotifications: [],
    updated: false,
  },
  mutations: {
    addToastNotification: (state, notification) => {
      state.toastNotifications = [...state.toastNotifications, notification];
    },

    removeToastNotification: (state, id) => {
      state.toastNotifications = state.toastNotifications
        .filter((notification) => notification.id !== id);
    },

    setUpdated: (state, updatedStatus) => {
      state.updated = updatedStatus;
    },
  },
  actions: {
    addToastNotification: ({ commit }, { error, ...rest }) => {
      const isDev = getEnvVar('NODE_ENV') === 'development';
      const newNotification = {
        ...rest,
        id: `${Date.now()}${Math.floor((1000 * Math.random()))}`,
        error: isDev && error,
      };
      commit('addToastNotification', newNotification);
    },

    addToastError: ({ dispatch }, notification) => {
      dispatch('addToastNotification', { ...notification, isError: true });
    },

    addToastSuccess: ({ dispatch }, notification) => {
      dispatch('addToastNotification', { ...notification });
    },

    removeToastNotification: ({ commit }, id) => {
      commit('removeToastNotification', id);
    },

    async setUpdatedStatus({ commit }, updatedStatus) {
      commit('setUpdated', updatedStatus);
    },
  },
};
