import cloneDeep from 'lodash/cloneDeep';
import { toCamelCase } from '../../utils';
import {
  getConversations,
  getMessages,
  sendMessage,
  markAsRead,
  getParentInfo,
  formatConversation,
  getUnreadBadge,
} from './messaging.service';

export default {
  namespaced: true,

  state: {
    conversations: [],
    messages: [],
    currentConversationId: undefined,
    conversationScrollEnabled: true,
    messagesScrollEnabled: true,
    lastMessageId: undefined,
    currentConversationFamily: {},
    newMessageReceived: false,
    unreadBadge: 0,
  },
  mutations: {
    setConversations: (state, { conversations, lastId, isFirstNewConversation }) => {
      if (lastId) {
        state.conversations.push(...conversations);
      } else if (isFirstNewConversation) {
        // if conversation returned in request, merge with new conversation placeholder
        // else push rest of conversations to the list
        const newConv = state.conversations[0];
        const idx = conversations.findIndex((c) => c.secondUser.type === newConv.secondUser.type
          && c.secondUser.id === newConv.secondUser.id);
        if (idx > -1) {
          const conversation = conversations.splice(idx, 1)[0];
          state.conversations[0] = conversation;
          state.currentConversationId = conversation.id;
        }
        state.conversations.push(...conversations);
      } else {
        state.conversations = conversations;
      }
    },
    setMessages: (state, messages) => {
      state.lastMessageId = messages[messages.length - 1].id;
      // group messages by sent date to display dates in chat
      // and reverse the messages to handle the inverted scrolling
      const groupedMessages = messages.reverse().reduce((groups, message) => {
        const date = new Date(message.createdAt).toDateString();

        const index = groups.findIndex((group) => group.date === date);
        if (index < 0) {
          groups.push({
            date,
            messages: [message],
          });
        } else {
          groups[index].messages.push(message);
        }
        return groups;
      }, []);

      // check if the new messages last date overlaps with the old messages dates
      const lastDate = groupedMessages[groupedMessages.length - 1].date;
      if (lastDate === state.messages[0]?.date) {
        state.messages[0].messages.unshift(...groupedMessages[groupedMessages.length - 1].messages);
        groupedMessages.pop();
      }
      state.messages = [...groupedMessages, ...state.messages];
    },
    pushMessage: (state, message) => {
      // check if date matches last group of messages
      const date = new Date(message.createdAt).toDateString();
      if (date === state.messages[state.messages.length - 1]?.date) {
        state.messages[state.messages.length - 1].messages.push(message);
      } else {
        state.messages.push({
          date,
          messages: [message],
        });
      }
    },
    changeConversation: (state, conversationId) => {
      state.messages = [];
      state.lastMessageId = undefined;
      state.currentConversationId = conversationId;
      state.messagesScrollEnabled = true;
      state.currentConversationFamily = {};
    },
    setConversationsScroll: (state, isEnabled) => {
      state.conversationScrollEnabled = isEnabled;
    },
    setChatScroll: (state, isEnabled) => {
      state.messagesScrollEnabled = isEnabled;
    },
    moveConversationToTop: (state, { message, isNewConversation, conversation }) => {
      const conversationId = isNewConversation ? 'newConversation' : message.conversationId;
      const convIdx = state.conversations.findIndex((c) => c.id === conversationId);
      if (convIdx > -1) {
        const conv = state.conversations.splice(convIdx, 1)[0];
        conv.id = message.conversationId;
        conv.lastMessage = message;
        if (message.secondUserId && !conv.secondUser?.id) conv.secondUser.id = message.secondUserId;
        state.conversations.unshift(conv);
      } else if (conversation) {
        state.conversations.unshift(conversation);
      }
    },
    markAsRead: (state, conversationId) => {
      const convIdx = state.conversations.findIndex((c) => c.id === conversationId);

      if (convIdx === -1) return;

      state.conversations[convIdx].lastMessage.read = true;
      state.conversations[convIdx].hasUnreadMessages = false;
    },
    newMessage: (state, {
      providerId, facilityId, facilityName, legupParentId,
    }) => {
      // add new conversation to top of the list
      const conversations = cloneDeep(state.conversations);
      conversations.unshift({
        id: 'newConversation',
        secondUser: {
          id: legupParentId ? undefined : providerId,
          type: legupParentId ? 'User' : 'Provider',
          name: legupParentId ? '' : facilityName,
          legupParentId,
        },
        lastMessage: {},
        createdAt: new Date(),
        facilityId,
      });
      state.conversations = conversations;
    },
    setConversationFamily: (state, family) => {
      state.currentConversationFamily = family;
    },
    setConversationReceiverName: (state, parent) => {
      state.conversations[0].secondUser.name = `${parent.firstName} ${(parent.lastName || '').charAt(0)}`;
    },
    resetMessaging: (state) => {
      state.conversations = [];
      state.messages = [];
      state.currentConversationId = undefined;
      state.conversationScrollEnabled = true;
      state.messagesScrollEnabled = true;
      state.lastMessageId = undefined;
      state.currentConversationFamily = {};
      state.newMessageReceived = false;
    },
    setNewMessageReceived: (state, isNewMessage) => {
      state.newMessageReceived = isNewMessage;
    },
    setUnreadBadge: (state, count) => {
      state.unreadBadge = count;
    },
    updateUnreadBadge: (state, value) => {
      state.unreadBadge += value;
    },
  },
  actions: {
    async fetchConversations(
      {
        commit, dispatch, state, rootState,
      },
      params,
    ) {
      let lastId = state.conversations[state.conversations.length - 1]?.id;
      const lastUpdatedAt = state.conversations[state.conversations.length - 1]?.lastUpdatedAt;
      let isFirstNewConversation = false;
      if (lastId === 'newConversation') {
        lastId = undefined;
        isFirstNewConversation = true;
      }
      try {
        const data = await getConversations({ lastUpdatedAt, ...params });
        if (data.length) {
          const conversations = data.map((c) => formatConversation(c, rootState.user.current));
          commit('setConversations', {
            lastId,
            conversations,
            isFirstNewConversation,
          });
        } else {
          commit('setConversationsScroll', false);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not get conversations', error }, { root: true });
      }
    },

    async fetchMessages({ commit, dispatch, state }, conversationId) {
      if (state.currentConversationId !== conversationId) {
        commit('changeConversation', conversationId);
      }
      try {
        const data = await getMessages({
          conversationId,
          lastId: state.lastMessageId,
        });
        if (data.length) {
          commit('setMessages', data);
        } else {
          commit('setChatScroll', false);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not get messages', error }, { root: true });
      }
    },
    async fetchUnreadBadge({ commit, dispatch }) {
      try {
        const data = await getUnreadBadge();
        commit('setUnreadBadge', data?.badgeCount);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not get messages badge', error }, { root: true });
      }
    },

    async sendMessage(
      {
        commit, dispatch, state, rootState,
      },
      {
        content, receiverId, conversationId, facilityId, legupParentId,
      },
    ) {
      const isNewConversation = conversationId === 'newConversation';
      const body = {
        content, secondUserId: receiverId, facilityId, legupParentId,
      };
      if (rootState.user.current.isParent) {
        body.senderType = 'User';
        body.senderId = rootState.user.current.id;
        body.secondUserType = 'Provider';
      } else {
        body.senderType = 'Provider';
        body.senderId = rootState.providerId;
        body.secondUserType = 'User';
      }
      try {
        const data = await sendMessage(body);
        if (!data) return;

        commit('moveConversationToTop', { message: data, isNewConversation });
        if (!isNewConversation) commit('pushMessage', data);
        if (!state.conversationId || isNewConversation) {
          state.currentConversationId = data.conversationId;
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not send message', error }, { root: true });
      }
    },

    async markConversationAsRead({ commit, dispatch }, conversationId) {
      try {
        const response = await markAsRead({ conversationId });
        if (!response.status === 200) {
          dispatch('notifications/addToastError', { text: 'Could not mark messages as read' }, { root: true });
        }
        commit('markAsRead', conversationId);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not mark message as read', error }, { root: true });
      }
    },

    async fetchParentInfo({
      commit, dispatch, state,
    }, body) {
      try {
        const response = await getParentInfo(body);
        commit('setConversationFamily', response);
        if (body.legupParentId && state.currentConversationId === state.conversations[0].id) {
          commit('setConversationReceiverName', response.legupParent);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch family', error }, { root: true });
      }
    },

    newMessageReceived({ commit, rootState }, message) {
      const messageFormatted = toCamelCase(message).newMessage;
      const userType = rootState.user.current.isParent ? 'User' : 'Provider';
      // drop message if current user is the sender
      if (userType !== messageFormatted.senderType) {
        commit('pushMessage', messageFormatted);
        commit('setNewMessageReceived', true);
      }
    },

    newConversationReceived({ commit, rootState, state }, conv) {
      const { conversation, newMessage, incBadge } = toCamelCase(conv);
      const userType = rootState.user.current.isParent ? 'User' : 'Provider';
      if (userType === newMessage.senderType) return;

      if (conversation.id === state.currentConversationId) {
        newMessage.read = conversation.id === state.currentConversationId;
      } else if (incBadge) {
        commit('updateUnreadBadge', +1);
      }
      const formattedConversation = formatConversation({
        ...conversation,
        facilityId: conversation.childCareFacilityId,
        lastMessage: newMessage,
      }, rootState.user.current);
      commit('moveConversationToTop', {
        message: newMessage, isNewConversation: false, conversation: formattedConversation,
      });
    },
  },
};
