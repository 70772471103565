import { axios } from '@utils';

async function fetchFacility(facilityId) {
  const { data } = await axios.get(`/child_care_facilities/${facilityId}.json`);
  return data;
}

async function updateFacility(data, facilityId) {
  await axios.post('/facility_logs.json', {
    child_care_facility_id: facilityId,
    facility_log: { ...data, source: 'insitu' },
  });
}
async function fetchFacilityDetails(facilitySlug) {
  const { data } = await axios.get(`/child_care_facilities/${facilitySlug}/details.json`);
  return data;
}

export {
  fetchFacility,
  updateFacility,
  fetchFacilityDetails,
};
