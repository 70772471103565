import { axios, toSnakeCase, toCamelCase } from '@utils';

const CONVERSATIONS_BASE_PATH = 'api/v1/conversations';
const MESSAGES_BASE_PATH = 'api/v1/messages';

async function getConversations(params) {
  const response = await axios.get(`/${CONVERSATIONS_BASE_PATH}`, {
    params: toSnakeCase(params),
  });
  return toCamelCase(response.data);
}

async function getMessages(params) {
  const response = await axios.get(`/${MESSAGES_BASE_PATH}`, {
    params: toSnakeCase(params),
  });
  return toCamelCase(response.data);
}

async function sendMessage(body) {
  const response = await axios.post(
    `/${MESSAGES_BASE_PATH}`,
    toSnakeCase(body),
  );
  return toCamelCase(response.data);
}

async function markAsRead(body) {
  const response = await axios.put(
    `/${MESSAGES_BASE_PATH}/mark_read`,
    toSnakeCase(body),
  );

  return response;
}

async function getParentInfo(params) {
  const response = await axios.get(`/${CONVERSATIONS_BASE_PATH}/parent_info`, {
    params: toSnakeCase(params),
  });
  return toCamelCase(response.data);
}

function formatConversation(conversation, currentUser) {
  const conv = {
    id: conversation.id,
    lastMessage: conversation.lastMessage,
    facilityId: conversation.childCareFacilityId,
    color: ['gold', 'periwinkle', 'coral', 'fuchsia', 'rust'][Math.floor(Math.random() * 5)],
    lastUpdatedAt: conversation.updatedAt,
    hasUnreadMessages: conversation.hasUnreadMessages,
  };
  if (currentUser.isParent) {
    conv.secondUser = {
      id: conversation.firstUserType === 'User' ? conversation.secondUserId : conversation.firstUserId,
      name: conversation.childCareFacilityName,
      type: 'Provider',
    };
  } else {
    conv.secondUser = {
      id: conversation.firstUserType === 'Provider' ? conversation.secondUserId : conversation.firstUserId,
      name: conversation.firstUserType === 'Provider' ? conversation.secondUser : conversation.firstUser,
      type: 'User',
      legupParentId: conversation.legupParentId,
    };
  }
  return conv;
}
async function getUnreadBadge() {
  const response = await axios.get(`/${CONVERSATIONS_BASE_PATH}/badge`);
  return toCamelCase(response.data);
}

export {
  getConversations,
  getMessages,
  sendMessage,
  markAsRead,
  getParentInfo,
  formatConversation,
  getUnreadBadge,
};
