import { axios } from '@utils';

const BASE_PATH = 'api/v1/accounts';

async function accountValidateEmployerCode(accessCode) {
  const response = await axios.post(`/${BASE_PATH}/validate_employer_code.json`, accessCode);
  return response;
}

async function accountResendConfirmationEmail() {
  const response = await axios.post(`/${BASE_PATH}/resend_confirmation_email.json`);
  return response;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  accountValidateEmployerCode,
  accountResendConfirmationEmail,
};
