import { addArrayToDictionary } from '@utils';
import {
  fetchDependents,
  removeDependent,
  addDependent,
  updateDependent,
} from './dependents.service';

export default {
  namespaced: true,

  state: {
    list: {},
  },
  getters: {
    dependentById: (state) => (depId) => (state.list[depId]),
    dependentIds: (state) => Object.keys(state.list),
    asArray(state) {
      return Object.values(state.list).filter((dependent) => dependent !== undefined);
    },
  },
  mutations: {
    clearDependents(state) {
      state.list = [];
    },
    updateDependents(state, dependents) {
      state.list = { ...addArrayToDictionary(dependents, state.list) };
    },
    updateDependent(state, dependent) {
      state.list[dependent.id] = dependent;
    },
    removeDependent(state, id) {
      const { [id]: removed, ...rest } = state.list;
      state.list = rest;
    },
  },

  actions: {
    async fetchDependents({ commit, dispatch }, params) {
      try {
        const dependents = await fetchDependents(params);
        commit('clearDependents');
        commit('updateDependents', dependents);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load dependents', error }, { root: true });
        throw new Error();
      }
    },
    async addDependents({ commit, dispatch }, dependents) {
      try {
        if (dependents && dependents.length) {
          const newDependents = await Promise.all(dependents.map((d) => addDependent(d)));
          commit('updateDependents', newDependents);
          return newDependents;
        }
        return [];
      } catch (error) {
        const names = dependents.map((d1) => d1.firstName).join(', ');
        dispatch('notifications/addToastError', { text: `Could not add dependents: ${names}`, error }, { root: true });
        throw new Error();
      }
    },
    async updateDependent({ commit, dispatch }, dependent) {
      try {
        if (dependent) {
          const updatedDependent = await updateDependent(dependent);
          commit('updateDependent', updatedDependent);
          return updatedDependent;
        }
        return undefined;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update dependent', error }, { root: true });
        throw new Error();
      }
    },
    async updateDependents({ commit, dispatch }, dependents) {
      try {
        const dependentsToUpdate = dependents.filter((d) => d.id !== -1);
        const dependentsToAdd = dependents.filter((d) => d.id === -1);
        const [addedDependents, ...updatedDependents] = await Promise.all([
          dispatch('addDependents', dependentsToAdd),
          ...dependentsToUpdate.map((d) => updateDependent(d)),
        ]);
        commit('updateDependents', updatedDependents);
        return { addedDependents, updatedDependents };
      } catch (error) {
        const names = dependents.map((d1) => d1.firstName).join(', ');
        dispatch('notifications/addToastError', { text: `Could not update dependents: ${names}`, error }, { root: true });
        throw new Error();
      }
    },
    async removeDependent({ commit, dispatch, state }, id) {
      try {
        await removeDependent(id);
        commit('removeDependent', id);
      } catch (error) {
        const dependent = state.list[id];
        dispatch('notifications/addToastError', { text: `Could not remove dependent: ${dependent.firstName} ${dependent.lastName}`, error }, { root: true });
        throw new Error();
      }
    },
  },
};
