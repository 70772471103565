import { objHasFalseyValues } from '../../utils';
import {
  fetchFacility,
  fetchFacilityDetails,
  updateFacility,
} from './facility.service';

import {
  fetchPhilosophies,
} from './search.service';

const INITIAL_PROFILE_EDIT_COMPLETENESS = {
  basicInfo: false,
  licensing: false,
  programDetails: false,
  photos: false,
  welcome: false,
  approach: false,
  healthSafety: false,
};

export default {
  namespaced: true,

  state: {
    facility: null,
    philosophies: null,
    profileCompletenessBySection: INITIAL_PROFILE_EDIT_COMPLETENESS,
  },

  mutations: {
    setFacility(state, facility) {
      state.facility = facility;
    },
    setPhilosophies(state, philosophies) {
      state.philosophies = philosophies;
    },
    setSectionCompleteness(state, { section, completeness }) {
      const newProfileCompletenessBySection = { ...state.profileCompletenessBySection };
      newProfileCompletenessBySection[section] = completeness;
      state.profileCompletenessBySection = newProfileCompletenessBySection;
    },
  },

  getters: {
    facilityInfo(state) {
      return state.facility;
    },
    basicInfoComplete(state) {
      if (!state.facility) return false;

      const fields = [
        'name',
        'facility_class',
        'address',
        'city',
        'state',
        'zip',
        'website',
        'email',
        'phone_number',
      ];
      const data = {};
      fields.forEach((field) => {
        data[field] = state.facility[field];
      });
      return !objHasFalseyValues(data);
    },
    licensingComplete(state) {
      if (!state.facility) return false;

      return !!state.facility.license_number;
    },
    keyDetailsComplete(state) {
      if (!state.facility) return false;

      const fields = [
        'age_months_to',
        'time_open_from',
        'time_open_to',
        'schedule_days_of_week',
      ];
      const data = {};
      fields.forEach((field) => {
        data[field] = state.facility[field];
      });
      return !objHasFalseyValues(data);
    },
    photosComplete(state) {
      if (!state.facility) return false;

      return state.facility.profile_images
        && state.facility.profile_images.length > 0;
    },
    welcomeComplete(state) {
      if (!state.facility) return false;

      const fields = [
        'profile_director',
        'profile_description',
        'administrator',
        'director_profile_photo',
      ];
      const data = {};
      fields.forEach((field) => {
        data[field] = state.facility[field];
      });
      return !objHasFalseyValues(data);
    },
    approachComplete(state) {
      if (!state.facility) return false;

      const fields = [
        'facility_philosophy_ids',
      ];
      const data = {};
      fields.forEach((field) => {
        data[field] = state.facility[field];
      });
      return !objHasFalseyValues(data);
    },
    healthSafetyComplete(state) {
      if (!state.facility) return false;

      const fields = [
        'profile_covid_response',
      ];
      const data = {};
      fields.forEach((field) => {
        data[field] = state.facility[field];
      });
      return !objHasFalseyValues(data);
    },
    isProfileEditComplete(state, getters) {
      return getters.numCompleteProfileEditSections === getters.totalProfileEditSections;
    },
    profileEditSections(state) {
      return Object.keys(state.profileCompletenessBySection);
    },
    numCompleteProfileEditSections(state, getters) {
      const sectionsBycompleteness = getters
        .profileEditSections.map((section) => getters[`${section}Complete`]);
      return sectionsBycompleteness.filter((section) => section).length;
    },
    totalProfileEditSections(state, getters) {
      return getters.profileEditSections.length;
    },
    profileEditCompleteness(state, getters) {
      return parseInt(
        (getters.numCompleteProfileEditSections / getters.totalProfileEditSections) * 100,
        10,
      );
    },
  },

  actions: {
    async fetchPhilosophies({ commit, dispatch }) {
      try {
        const philosophies = await fetchPhilosophies();
        await commit('setPhilosophies', philosophies);
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not load philosophies',
          error,
        }, { root: true });
      }
    },
    async fetchFacility({ commit, dispatch }, facilityId) {
      try {
        const facility = await fetchFacility(facilityId);
        await commit('setFacility', facility);
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not load facility',
          error,
        }, { root: true });
      }
    },
    async fetchFacilityDetails({ commit, dispatch }, facilitySlug) {
      try {
        const facility = await fetchFacilityDetails(facilitySlug);
        await commit('setFacility', facility);
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'Could not load facility',
          error,
        }, { root: true });
      }
    },
    async updateFacility({ rootState, dispatch }, data) {
      try {
        await updateFacility(data, rootState.facilityId);
        await dispatch('fetchFacility', rootState.facilityId);
      } catch (error) {
        dispatch('notifications/addToastError', {
          text: 'This facility could not be updated, please ensure all information is correct and try again.',
          error,
        }, { root: true });
      }
    },
  },
};
