import { axios, toCamelCase, toSnakeCase } from '@utils';

const notificationSettings = [
  { value: 'all', label: 'Receive all emails' },
  { value: 'important', label: 'Receive important emails only' },
];

async function removeAvatar() {
  return axios.delete('/avatar');
}

async function updateNotificationSettings(settings) {
  return axios.patch('/notification_settings', { user: { email_settings: settings } });
}

async function getUser() {
  const { data } = await axios.get('/user.json');
  return toCamelCase(data.user);
}

async function updateUser(userObj) {
  const { isImpersonated, ...user } = userObj;
  const response = await axios.put('/user.json', { user: toSnakeCase(user) });
  return toCamelCase(response.data.user);
}

async function updatePassword(password, passwordConfirmation) {
  const response = await axios.put(
    '/users.json',
    {
      user: {
        password,
        password_confirmation: passwordConfirmation,
      },
    },
  ).catch(() => false);

  return response;
}

async function getUserNavData(email) {
  const { data } = await axios.get('/api/v1/legup/users/nav', {
    params: {
      email,
    },
  });

  return toCamelCase(data);
}

export default {
  removeAvatar,
  updateNotificationSettings,
  getUser,
  updateUser,
  updatePassword,
  notificationSettings,
  getUserNavData,
};
